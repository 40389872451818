import { Head, Notes, Appear, Split, Image, Horizontal } from "mdx-deck";
import { prism } from "@mdx-deck/themes";
import BigBlock from "../../../../../../lib/BigBlock";
import FullImage from "../../../../../../lib/FullImage";
import theme from "../../../../../../lib/theme";
import battery from "../../../../../../img/battery.png";
import barak_mark from "../../../../../../img/barak_mark.jpg";
import obama_naked from "../../../../../../img/obama_naked.jpg";
import eggs from "../../../../../../img/eggs.jpg";
import pubsub1 from "../../../../../../img/pubsub1.png";
import pubsub2 from "../../../../../../img/pubsub2.png";
import highlight from "../../../../../../img/highlight.jpg";
import freewrite from "../../../../../../img/freewrite.jpg";
import freewrite2 from "../../../../../../img/freewrite2.jpg";
import pomodoro from "../../../../../../img/pomodoro.png";
import spotify from "../../../../../../img/spotify.png";
import make_time from "../../../../../../img/make_time.jpg";
import willpower from "../../../../../../img/willpower.jpg";
import crazy_at_work from "../../../../../../img/crazy_at_work.png";
import matilda from "../../../../../../img/matilda.jpg";
import matilda_wardrobe from "../../../../../../img/matilda_wardrobe.jpg";
import matilda_weekend from "../../../../../../img/matilda_weekend.jpg";
import React from 'react';
export default {
  Head: Head,
  Notes: Notes,
  Appear: Appear,
  Split: Split,
  Image: Image,
  Horizontal: Horizontal,
  prism: prism,
  BigBlock: BigBlock,
  FullImage: FullImage,
  theme: theme,
  battery: battery,
  barak_mark: barak_mark,
  obama_naked: obama_naked,
  eggs: eggs,
  pubsub1: pubsub1,
  pubsub2: pubsub2,
  highlight: highlight,
  freewrite: freewrite,
  freewrite2: freewrite2,
  pomodoro: pomodoro,
  spotify: spotify,
  make_time: make_time,
  willpower: willpower,
  crazy_at_work: crazy_at_work,
  matilda: matilda,
  matilda_wardrobe: matilda_wardrobe,
  matilda_weekend: matilda_weekend,
  React: React
};